
// 万效云
const EfficiencyCloud = {
  'frist': [
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff01.png"),
      title: 'AR/VR趣味营销内容',
      text: '多种玩法的AR互动游戏以及VR全景沉浸式内容展示，提升用户量扩大品牌影响力、提升营销效果'
    },
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff02.png"),
      title: '精准定位目标人群',
      text: '自动托管，通过强大的计算能力，精准定位目标人群，锁定目标用户，大幅提升ROI'
    },
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff03.png"),
      title: '结算更透明',
      text: '价格透明、数据透明、流程透明；仅收取平台技术服务费用，绝不赚取中间差价，不扣量'
    },
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff04.png"),
      title: '营销阵地',
      text: '对接巨量引擎、广点通、百度信息流等主流媒体投放平台'
    }
  ],
  'second': [
    {
      id: 1,
      tabTitle: 'AR场景广告',
      img: require("@/assets/images/cc.jpg"),
      lunTitle: '趣味游戏+广告',
      lunTitle1: '打造个性化AR营销',
      tip: '将广告内容与AR互动小游戏完美结合，扩大品牌影响力的同时提升广告效果',
      btns:[
        {
          id:1,
          button:'游戏开始前',
          src1: require('../../public/img/icons/EfficiencyCloud/eff01small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff01Big1pc.png")
        },
        {
          id:2,
          button:'游戏中',
          src1: require('../../public/img/icons/EfficiencyCloud/eff02small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff02Big1pc.png")
        }
      ]
    }, 
    {
      id: 2,
      tabTitle: 'VR场景广告',
      img: require("@/assets/images/cc.jpg"),
      lunTitle: '支持多种类型的广告载具完美契合全景内容',
      tip: '透明载具、X展架、电视机、大屏等多种广告载具，与全景内容相融合，颠覆传统广告展现形式',
      btns:[
        {
          id:3, 
          button:'开屏广告', 
          src1: require('../../public/img/icons/EfficiencyCloud/eff04small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff04Bigpc.png")
        },
        {
          id:4, 
          button:'嵌入式', 
          src1: require('../../public/img/icons/EfficiencyCloud/eff05small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff05Bigpc.png")
        }
      ]
    },
  ],
  'four': [
    {
      img: require('@/assets/solutions/944.png'),
      title: '线下门店场景',
      text: '引流到店、老带新，提高客单价'
    },
    {
      img: require('@/assets/solutions/944.png'),
      title: '电商场景',
      text: '店铺引流、关注，引导购买，提升GMV'
    },
    {
      img: require('@/assets/solutions/944.png'),
      title: '微信生态场景',
      text: '拉新、活跃，关注公众号为私域流量构建持续蓄力'
    },
    {
      img: require('@/assets/solutions/944.png'),
      title: 'App应用场景',
      text: 'API对接实现各类权益体系互通助力用户长线运营'
    }
  ]
}
// 数字博物馆
const DigitalMuseum = {
  // 产品特色
  'warp1': {
    charList: [
      {
        src: require("../../public/img/icons/DigitalMuseum/dig01.png"),
        title: "1:1场景还原",
        content: "完美还原历史场景，呈现逼真效果"
      },
      {
        src: require("../../public/img/icons/DigitalMuseum/dig02.png"),
        title: "文物3D建模",
        content: "详尽的采集文物数据，精细化建模"
      },
      {
        src: require("../../public/img/icons/DigitalMuseum/dig03.png"),
        title: "线上游览",
        content: "强大VR技术，线上完美复刻博物馆"
      },
      {
        src: require("../../public/img/icons/DigitalMuseum/dig04.png"),
        title: "数字化运营",
        content: "数字管控系统，在线管理博物馆"
      }
    ],
  },
  // 数字博物馆产品模块
  'warp2': {
    title: '数字博物馆产品模块',
    list: [
      {
        id: 1,
        tabTitle: "3D模型",
        img: "https://h5.ophyer.cn/official_website/other/DigitalMuseum01.png",
        lunTitle: "3D模型",
        // tip: "• 3D建模，轻松搭建数字博物馆",
        contents: [
          "• 自主研发引擎，高精度的3D模型",
          "• 通过云端技术渲染后推送到客户端",
          "• 搭配3D动画，让文物的展示更鲜活"
        ]
      },
      {
        id: 2,
        tabTitle: "AR增强现实",
        img: "https://h5.ophyer.cn/official_website/other/DigitalMuseum02.png",
        lunTitle: "AR增强现实",
        // tip: "• 图片识别，文物即可“跃然纸上”",
        contents: [
          "• 图片识别，即可展现文物3D模型",
          "• 场景识别，展示历史遗迹的本来面目"
        ]
      },      
      {
        id: 3,
        tabTitle: "VR线上展厅",
        img: "https://h5.ophyer.cn/official_website/other/DigitalMuseum03.png",
        lunTitle: "VR线上展厅",
        // tip: "• 沉浸式交互体验，无需到达现场即可完成线上游览",
        contents: [
          "• 720°可交互全景，为您量身打造线上虚拟展厅",
          "• 全景编辑制作平台，简单操作即可生成场景",
          "• 设置电子沙盘，全局总览，规划合理路线",
          "• 添加语音视频等热点，让线上游览体验更完整"
        ]
      },
      {
        id: 4,
        tabTitle: "数字管控系统",
        img: "https://h5.ophyer.cn/official_website/other/DigitalMuseum04.png",
        lunTitle: "数字化管理系统型",
        // tip: "• 实现博物馆收藏、展示、数据采集等业务智能化",
        contents: [
          "• 博物馆资源管理模块，方便博物馆管理展示资源",
          "• 数据管理模块、随时查看文物浏览及活动数据"
        ]
      },      
    ]
  },
  // 使用场景
  'warp3': {
    sceneList: [
      {
        title: "文物博物馆",
        src: "https://h5.ophyer.cn/official_website/other/DigitaScene01.png"
      },
      {
        title: "艺术展览馆",
        src: "https://h5.ophyer.cn/official_website/other/DigitaScene02.png"
      },
      {
        title: "科技展览馆",
        src: "https://h5.ophyer.cn/official_website/other/DigitaScene03.png"
      },
      {
        title: "大学文化宣传",
        src: "https://h5.ophyer.cn/official_website/other/DigitaScene04.png"
      }
    ]
  },


  
}
// vr带看
const VRSceneShow = {
  'wrap1': {
    list: [
      {
        title:'高质量VR全景', 
        text:'3D实景复刻，还原真实空间',
        src: require("../../public/img/icons/VRSceneShow/p1.jpg"),
      },
      {
        title:'专业主播', 
        text:'专业主播引导浏览，让场景展示更直观',
        src: require("../../public/img/icons/VRSceneShow/p2.jpg"),
      },
      {
        title:'实时弹幕', 
        text:'弹幕互动，主播实时查看，讲解更高效',
        src: require("../../public/img/icons/VRSceneShow/p3.jpg"),
      },
      {
        title:'多人联动', 
        text:'可同时容纳多人进入直播间',
        src: require("../../public/img/icons/VRSceneShow/p4.jpg"),
      },
    ],
  },
  'wrap2' : {
    content:[
      {
        id:1,
        h2:'扎实的云端技术为您线上带看提供急速、稳定、专业的直播处理服务',
        img: require("../../public/img/icons/VRSceneShow/pro01.png"),
        cardTitle:'链接稳定',
      },
      {
        id:2,
        h2:'主播通过弹幕与观众实时互动，可以根据观众的需求做出对应的讲解',
        img: require("../../public/img/icons/VRSceneShow/pro02.png"),
        cardTitle:'在线互动',
      },
      {
        id:3,
        h2:'根据需求，为您量身定制，完美还原场景，搭配专业的讲解词汇',
        img: require("../../public/img/icons/VRSceneShow/pro03.png"),
        cardTitle:'量身定制',
      },
    ],
  }
}
// 解决方案AR
const solutionAR = {
  'wrap1': {
    cards: [
      { 
        id:1, 
        src: require("../../public/img/icons/solutionAR/sol01.png"),
        tit:'图形识别'},
      { 
        id:2,  
        src: require("../../public/img/icons/solutionAR/sol02.png"),
        tit:'物体识别' 
      },
      { 
        id:3,  
        src: require("../../public/img/icons/solutionAR/sol03.png"),
        tit:'人脸识别' },
      { 
        id:4,  
        src: require("../../public/img/icons/solutionAR/sol04.png"),
        tit:'陀螺仪追踪' 
      },
      { 
        id:5,  
        src: require("../../public/img/icons/solutionAR/sol06.png"),
        tit:'3D动画' 
      },
      { 
        id:6,  
        src: require("../../public/img/icons/solutionAR/sol05.png"),
        tit:'到店扫码' 
      },
      { 
        id:7,  
        src: require("../../public/img/icons/solutionAR/sol07.png"),
        tit:'透明Webivew' 
      },
      { 
        id:8,  
        src: require("../../public/img/icons/solutionAR/sol08.png"),
        tit:'贴片动画' 
      },
    ],
  },
  'wrap2': {
    items: [
      { 
        h1:'品牌宣传', 
        title:"AR识别品牌LOGO加深品牌印象", 
        src: 'https://h5.ophyer.cn/official_website/other/serve01-1.png',
        content:'识别图、游戏元素、结算页面等均可添加品牌LOGO，提高品牌曝光度，加深印象'
      },
      { 
        h1:'线下引流获客', 
        title:"丰富玩法结合礼包、门店券、礼品卡为门店强势引流", 
        src: 'https://h5.ophyer.cn/official_website/other/serve02-1.png',
        content:'分层、抽奖、集卡等丰富的营销组件，让用户在游戏的过程中获得奖励，引导线下核销'
      },
      { 
        h1:'线上产品促销', 
        title:"优惠券、积分等权益奖励有效促进线上转化，提升GMV", 
        src: 'https://h5.ophyer.cn/official_website/other/serve03-1.png',
        content:'通过发放优惠券、积分以及直接跳转线上门店，有效拉客单，促进用户消费'
      },
      { 
        h1:'公众号吸粉', 
        title:"通过授权公众号，有效吸粉养粉构建私域流量", 
        src: 'https://h5.ophyer.cn/official_website/other/serve04-1.png',
        content:'用户可授权自主公众号，将公众号二维码展示在游戏界面，为公众号吸粉'
      },
      { 
        h1:'裂变增长', 
        title:"分享复活及朋友圈海报有效促进活动", 
        src: 'https://h5.ophyer.cn/official_website/other/serve05-1.png',
        content:'通过分享增加活动参与次数，促进用户自传播，',
        content1:'提升活动曝光率'
      },
    ],
  },
  'warp3': {
    sceneList: [
      {
        title: "资源位/游戏专题",
        src: "https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      },
      {
        title: "公众号菜单跳转",
        src: "https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      },
      {
        title: "微信扫图识别",
        src: "https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      },
      {
        title: "朋友圈海报分享",
        src: "https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      }
    ]
  },
}

// 视听云----- 解决方案
const audioSolution = {
  "wrap1": {
    title: '存储空间认证',
    titleEng: "STORAGE SPACE",
    list:[
      {
        title: '企业现有的云空间',
        content:'如：阿里云OSS、腾讯云COS七牛云对象存储等服务',
        src: "https://h5.ophyer.cn/official_website/other/ausol2-11.png",
      },
      {
        src: require("../../public/img/icons/audioSolution/contact.png"),
      },
      {
        title: '绑定citv.vn子域名',
        content:'配置Bucket权限视频审核自动由CITV完成',
        src: "https://h5.ophyer.cn/official_website/other/ausol2-2.png",
      },
    ]
  },
  "wrap2": {
    title: '根据客户需求，定制服务内容',
    titleEng: "CUSTOMIZED SERVICE CONTENT",
    list: [
      {
        tabTitle: '中小企业',
        img: "https://h5.ophyer.cn/official_website/other/ausol1-1.png",
        childrenTitle: '储存空间认证服务',
        children: ["• 最优性价比","• 轻松实现合规","• 多种套餐可选","• 最多用户选择"]
      },
      {
        tabTitle: 'UGC平台企业',
        img: "https://h5.ophyer.cn/official_website/other/ausol1-2.png",
        childrenTitle: 'UGC伙伴计划',
        children: ["• 开展海量用户上传视频或直播"]
      },
      {
        tabTitle: '针对大型企业',
        img: "https://h5.ophyer.cn/official_website/other/ausol1-3.png",
        childrenTitle: '融媒体咨询及构建服务',
        children: ["• 健全的合规体系","• 企业融媒体战略","• 精致服务"]
      },
    ]
  },
  "wrap3": {
    title: '企业融媒体全案',
    titleEng: "ENTERPRISE CASE",
    list: [
      {
        title: '视频上传三方平台',
        content:'如：阿里云OSS、腾讯云COS七牛云对象存储等服务',
        src: require("../../public/img/icons/audioSolution/ausol3-1.png"),
        children: ["• 规则由三方制定","• 第三方靠广告赚钱","•  品牌形象尽失"]
      },
      {
        title: '运营企业“自媒体”',
        content:'配置Bucket权限视频审核自动由CITV完成',
        src: require("../../public/img/icons/audioSolution/ausol3-1.png"),
        children: ["• 本质是第三方媒体","• 粉丝是第三方用户","• 企业为第三方贡献内容"]
      },
    ],
    listone:[
      {
        title: '企业自建融媒体全媒体平台',
        content:'配置Bucket权限视频审核自动由CITV完成',
        src: require("../../public/img/icons/audioSolution/ausol3-1.png"),
        children: ["• 真正意义的自有媒体","• 打通企业内培、会务、营销、客服、品牌、公关等各个环节","• 与三方视频平台、企业“自媒体”高效协同"]
      },
    ]
  },
  "wrap4": {
    title: 'UGC伙伴计划',
    titleEng: "PARTNERSHIP PROGRAM",
    tip:'CITV为UGC伙伴开辟专有视听板块、培训审核人员，建立完善的播出安全体系',
    list: [
      {
        title: '直播',
        src: require("../../public/img/icons/audioSolution/ausol4-1.png"),
      },
      {
        title: '点播',
        src: require("../../public/img/icons/audioSolution/ausol4-2.png"),
      },
      {
        title: '视频购物',
        src: require("../../public/img/icons/audioSolution/ausol4-3.png"),
      },
      {
        title: 'VR/AR',
        src: require("../../public/img/icons/audioSolution/ausol4-4.png"),
      },
      {
        title: '在线教育',
        src: require("../../public/img/icons/audioSolution/ausol4-5.png"),
      },
    ]
  },
  "wrap5": {
    title: '增值服务',
    titleEng: "VALUE ADDED SERVICES",
    list: [
      {
        title: '视频内容策划+制作',
        src: "https://h5.ophyer.cn/official_website/other/ausol5-1.png",
      },
      {
        title: '主播+拍摄+运营培训',
        src: "https://h5.ophyer.cn/official_website/other/ausol5-2.png",
      },
      {
        title: '媒体矩阵/代运营',
        src: "https://h5.ophyer.cn/official_website/other/ausol5-3.png",
      },
    ]
  },
}
// 视听云---申请
const application = {
  'app' : {
    tip:'温馨提示：',
    text:[
      '1. 开通云视听认证服务，请提前开通公有云“对象存储”或“视频点播服务”，例如：阿⾥云对象存储、阿 ⾥云视频点播、腾讯云对象存储、腾讯云点播、七⽜云对象存储、华为云对象存储等',
      '2. 请在以下表单中选择您的公有云服务商，并正确填写相关配置信息',
      '3. 提交资料并⽀付成功后，请耐⼼等待1-2个⼯作⽇，我们的⼯作⼈员会尽快为您开通服务。如需联系 请拨打电话010-63037996',
    ],
    contact:'对套餐和服务有疑问？详细服务内容请联系我们的服务人员：韩经理 18611320166',
    WXqrcode: require("../../public/img/icons/pay/icon-wx.png"),
    ZFBqrcode: require("../../public/img/icons/pay/icon-zfb.png"),
    WXpayImg: require("../../public/img/icons/pay/icon-wechatPay.png"),
    ZFBpayImg: require("../../public/img/icons/pay/icon-pay.png"),
  },
}

// 大数据分析----角色解决方案
const roleSolution = {
  "wrap1": {
    list: [
      {
        tabTitle: 'CEO',
        img: "https://h5.ophyer.cn/official_website/other/wmyHome01-1.png",
        // childrenTitle: '储存空间认证服务',
        // children: ["• 最优性价比","• 轻松实现合规","• 多种套餐可选","• 最多用户选择"]
        children:[
          {
            img: "https://h5.ophyer.cn/official_website/other/wmyHome01-1.png",
            upTit:'CEO管理看板',
            upCon:'数据整合、流转与管理。看板共享功能及健全的权限管理体系，让数据在企业职员之间高效流转；整合多平台用户行为数据，支持原始数据导出，让数据管理更便捷。'
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/wmyHome01-1.png",
            upTit:'各业务部门指标看板',
            upCon:'以业务为核心，构建市场、运营、产品各部门指标衡量体系，数据分析驱动科学决策，实现快速增长。',
            direction:true,
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/wmyHome01-1.png",
            upTit:'移动端随时访问',
            upCon:'可在移动端实时访问看板，数据驱动随时随地，核心数据一手掌握。'
          },
        ]
      },
      {
        tabTitle: '市场',
        img: "https://h5.ophyer.cn/official_website/other/wmyHome02-1.png",
        children:[
          {
            img: "https://h5.ophyer.cn/official_website/other/wmyHome02-1.png",
            upTit:'投放效果评估',
            upCon:'DSP投放、SEM推广、移动推广、渠道…轻松衡量各个渠道带来的用户数量，用数据复盘营销Campaign，不断优化获客成本。'
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/wmyHome02-1.png",
            upTit:'落地页优化',
            upCon:'落地页热力分析可直观的对比和分析用户在页面的聚焦度、页面浏览次数和人数以及页面内各个可点击元素的百分比，优化落地页，让流量留下来。',
            direction:true,
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/wmyHome02-1.png",
            upTit:'投放数据与业务行为打通',
            upCon:' 支持自定义转化目标，基于业务数据与行为数据，您将深入沉淀用户标签，每一个标签组，都将诞生运营奇迹。'
          },
        ]
      },
      {
        tabTitle: '运营',
        img: "https://h5.ophyer.cn/official_website/other/wmyHome03-1.png",
        children:[
          {
            upTit:'运营活动评估',
            upCon:'轻松衡量每次运营活动的对拉新、留存、活跃、转化等目标的效果，并找到合理优化的方向。'
          },
          {
            upTit:'留存复购提升',
            upCon:'依据用户的属性、行为、阶段，划分用户群组；为高价值用户定制更适合他们的内容、活动或触达方式，更好地提升用户留存率与复购率，让忠诚的用户欲罢不能。',
            direction:true,
          },
          {
            upTit:'流失用户召回',
            upCon:'流失不可怕，找到原因，并努力挽回才是王道。还原报表和数字背后每一个真实用户的行为图谱，亲历用户的完整操作流程，用数据做最客观的用户研究。'
          },
        ]
      },
      {
        tabTitle: '分析师',
        img: "https://h5.ophyer.cn/official_website/other/wmyHome03-1.png",
        children:[
          {
            upTit:'自助SQL分析',
            upCon:'灵活的可视化分析组件，赋予了企业各成员自助分析的能力。减轻分析师的重复性工作，将精力聚焦于业务的深度分析，为企业创造更大价值。同时，诸葛io为数据分析师及有需求的高级用户提供了SQL查询功能。您可以使用SQL表达式快速查询和分析用户。'
          },
          {
            upTit:'深挖底层数据定制报告',
            upCon:'根据业务方的不同的分析指标，一个页中可以创建多个SQL查询进行查询分析，并支持将查询结果进行的处理，或和其他数据（如业务数据库中的数据）进行整合，以便产出进一步的分析结果或报告，而满足分析师针对不同的业务需求的、更为复杂、更为深入的数据查询、分析和洞察。',
            direction:true,
          },

        ]
      },
      {
        tabTitle: '工程师',
        img: "https://h5.ophyer.cn/official_website/other/wmyHome03-1.png",
        children:[
          {
            upTit:'节约研发成本',
            upCon:'可支持业务部门的数据分析需求，无需投入研发精力进行数据清洗、数据加工、数据加载、数据建模等环节，大幅节省研发成本'
          },
          {
            upTit:'减少精力投入及时间成本',
            upCon:'一个账号接入SDK即可开启数据之旅。大幅减少研发精力投入，使之全情投入核心产品研发，降低沉没成本。即开即用，快速获得数据价值。',
            direction:true,
          },
        ]
      },
    ]
  },
  'wrap2': {
    title: '相关产品',
    titleEng: "WANMUYUNMARKETING",
    items: [
      { 
        title:"AI智能", 
        src: 'https://h5.ophyer.cn/official_website/other/serve01-1.png',
        content:'源于实践的行业方案，赋能产业AI升级'
      },
      {  
        title:"营销导流", 
        src: 'https://h5.ophyer.cn/official_website/other/serve02-1.png',
        content:'聚合国内主流媒体，结合AR/VR趣味内容轻松为广告找到优质用户'
      },
      { 
        title:"视听云", 
        src: 'https://h5.ophyer.cn/official_website/other/serve03-1.png',
        content:'简单易用，视听业务更安全合规，让企业安心开展直播、点播业务'
      },
    ],
  },
}
// 大数据分析----行业解决方案
const industrySolution = {
  "wrap1": {
    list: [
      {
        tabTitle: '行业解决方案',
        children:[
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol1-1.png",
            upTit:'电商零售',
            untip:'拓展数据思维，实现平稳转型',
            upCon:'帮助客户拓展零售行业的数据思维，呈现精准用户画像，明确用户潜在需求，平稳度过转型期。'
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol1-2.png",
            upTit:'教育行业',
            untip:'精准定位课程的高质量渠道',
            upCon:' 面对转型趋势不盲从，锁定不同客群，线上线下协同生长；独具“获取”分析，锁定TOP10渠道，高速成长。',
            direction:true,
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol1-3.png",
            upTit:'汽车行业',
            untip:'助力企业精准定向目标用户',
            upCon:'助力企业整合贯通线上线下数据，以用户为中心全生命周期洞察用户，试驾、赠礼、折扣、会员活动…抓住不同用户的兴趣点，定位目标用户'
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol1-4.png",
            upTit:'新媒体内容',
            untip:'优化运营策略，实现精准运营',
            upCon:'独具渠道“获取分析”，从后续留存、转化情况分析渠道质量； 即时梳理热度排行，关注用户的波动变化，面向不同的用户群进行有效运营',
            direction:true,
        
          },
        ]
      },
      {
        tabTitle: '角色解决方案',
        children:[
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol2-1.png",
            upTit:'CEO',
            untip:'多维度数据看板，数据管理更便捷',
            upCon:'数据整合、流转与管理，让数据在企业职员之间高效流转；整合多平台用户行为数据，支持原始数据导出，让数据管理更便捷'
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol2-2.png",
            upTit:'市场人员',
            untip:'数据复盘营销，效果投放评估',
            upCon:' DSP投放、SEM推广、移动推广、渠道……轻松衡量各个渠道带来的用户数量，对渠道质量进行全面、快速、精确的数据复盘',
            direction:true,
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol2-3.png",
            upTit:'运营人员',
            untip:'活动评估，留存复购提升',
            upCon:' 轻松衡量每次运营活动的对拉新、留存、活跃、转化等目标的效果，并找到合理优化的方向，为高价值用户定制最佳转化方案',
          },
          {
            img: "https://h5.ophyer.cn/official_website/other/bigDataSol2-4.png",
            upTit:'分析师',
            untip:'深挖底层数据定制报告',
            upCon:'根据业务方的不同的分析指标，一个页中可以创建多个SQL查询进行查询分析，满足分析师针对不同的业务需求的、更为复杂、更为深入的数据查询、分析和洞察。',
            direction:true,
          },
        ]
      },
    ]
  },
  'wrap2': {
    title: '相关产品',
    titleEng: "WANMUYUNMARKETING",
    items: [
      { 
        title:"AI智能", 
        src: 'https://h5.ophyer.cn/official_website/other/serve01-1.png',
        content:'源于实践的行业方案，赋能产业AI升级'
      },
      {  
        title:"营销导流", 
        src: 'https://h5.ophyer.cn/official_website/other/serve02-1.png',
        content:'聚合国内主流媒体，结合AR/VR趣味内容轻松为广告找到优质用户'
      },
      { 
        title:"视听云", 
        src: 'https://h5.ophyer.cn/official_website/other/serve03-1.png',
        content:'简单易用，视听业务更安全合规，让企业安心开展直播、点播业务'
      },
    ],
  },
  'scenes':[
    {
      img:'https://h5.ophyer.cn/official_website/other/storeSolution3-1.png',
      title:'AI智能',
      content:'源于实践的行业方案，赋能产业AI升级',
      router: "/AI/AIHome",
      style:'background:linear-gradient(180deg, #688AF3, #B7DDFB)'
    },
    {
      img:'https://h5.ophyer.cn/official_website/other/storeSolution3-2.png',
      title:'营销导流',
      router: "/bigDataAnalysis/home",
      content:'聚合国内主流媒体，结合AR/VR趣味内容轻松为广告找到优质用户',
      style:'background: linear-gradient(180deg, #34A0FE, #7ECCFE)'
    },
    {
      img:'https://h5.ophyer.cn/official_website/other/storeSolution3-3.png',
      title:'视听云',
      router: "/audiovisualCloud/home",
      content:'简单易用，视听业务更安全合规，让企业安心开展直播、点播业务',
      style:'background:linear-gradient(180deg, #2574FC, #4DDEFF)'
    }
  ],
}
//  大数据分析----首页
const bigDataAnalysis = {
  'wrap1': {
    title: '数字赋能营销转化',
    titleEng: "WANMUYUNMARKETING",
    tip:'通过多维用户洞察，灵活多维度分析，为企业量身打造综合性营销解决方案',
    list: [
      {
        title:'智能投放', 
        text:'结合大数据深度分析用户特征并寻找相似客群，助力广告精准投放',
        src: require("../../public/img/icons/bigDataHome/bigData1-1.png"),
      },
      {
        title:'拓客运营', 
        text:'精准定位获客渠道，挖掘用户价值提升留存与复购',
        src: require("../../public/img/icons/bigDataHome/bigData1-2.png"),
      },
      {
        title:'聚合营销', 
        text:'根据企业需求整合现有营销渠道，量身定制综合性营销解决方案',
        src: require("../../public/img/icons/bigDataHome/bigData1-3.png"),
      },
    ],
  },
  'wrap2': {
    title: '应用场景',
    titleEng: "WANMUYUNMARKETING",
    tip:'满足企业市场、运营、产品、分析师等不同部门的数据分析需求',
    list: [
      {
        tabTitle: '营销优化',
        src: "https://h5.ophyer.cn/official_website/other/dataHome2-1.png",
        content:'链接用户广告行为与应用行为，从数据中提取价值，深度洞察用户，高效的营销分析，实现营销自动化。',
        childrenTitle:'全渠道营销场景化分析'
      },
      {
        tabTitle: '用户运营',
        src: "https://h5.ophyer.cn/official_website/other/dataHome2-2.png",
        content:'精准定位获客渠道，多维度的筛选用户群，挖掘用户价值，优化用户体验，提升留存转化，提升留存与复购。',
        childrenTitle:'用户全生命周期管理建立全局增长视角'
      }
    ]
  },
  'wrap3': {
    title: '整合应用',
    titleEng: "WANMUYUNMARKETING",
    tip:'支持文本数据及主流数据库，安全接入Mysql等多种数据库，使类型杂、种类多的数据得到整合应用 ',
    list: [
      {
        title:'智能投放', 
        text:'结合大数据深度分析用户特征并寻找相似客群，助力广告精准投放',
        src: require("../../public/img/icons/VRSceneShow/p1.jpg"),
      },
      {
        title:'拓客运营', 
        text:'精准定位获客渠道，挖掘用户价值提升留存与复购',
        src: require("../../public/img/icons/VRSceneShow/p2.jpg"),
      },
      {
        title:'聚合营销', 
        text:'根据企业需求整合现有营销渠道，量身定制综合性营销解决方案',
        src: require("../../public/img/icons/VRSceneShow/p3.jpg"),
      },
    ],
  },
}
// 大数据分析 ----- 产品服务
const productService = {
  'wrap1': {
    title: '数据采集',
    titleEng: "WANMUYUNMARKETING",
    list: [
      {
        tabTitle:'采集方案', 
        children:[
          {
            title:'方案设计', 
            text:'制定完整和标准的数据采集方案，保障数据采集贴合企业数据分析的应用场景。',
            src: require("../../public/img/icons/bigDataProductService/service1.png"),
          },
          {
            title:'协作流程', 
            text:'针对数据采集设计高效的协作流程，避免因人员变动及业务更新导致的数据采集断层',
            src: require("../../public/img/icons/bigDataProductService/service2.png"),
          },
          {
            title:'管理', 
            text:'统一的规范化管理是数据采集能够持续的关键，是企业针对数据管理中有效把控。',
            src: require("../../public/img/icons/bigDataProductService/service3.png"),
          }
        ]
      },
      {
        tabTitle:'采集能力', 
        children:[
          {
            title:'无埋点', 
            text:'无需开发，业务人员埋点无需埋点，方便快捷可实现全量的数据采集',
            src: require("../../public/img/icons/bigDataProductService/service4.png"),
          },
          {
            title:'可视化埋点', 
            text:'满足快速验证场景需求解放研发工程师减少内部沟通成本',
            src: require("../../public/img/icons/bigDataProductService/service5.png"),
          },
          {
            title:'代码埋点', 
            text:'最可控的埋点方式复杂业务数据采集满足需要精确采集场景',
            src: require("../../public/img/icons/bigDataProductService/service6.png"),
          },
          {
            title:'后端埋点', 
            text:'融合各类算法模型，实现可视化分析及数据趋势预测，让策略更可靠。',
            src: require("../../public/img/icons/bigDataProductService/service7.png"),
          }
        ]
      },
    ],
  },
  'wrap2': {
    title: '相关产品',
    titleEng: "WANMUYUNMARKETING",
    items: [
      { 
        title:"AI智能", 
        src: 'https://h5.ophyer.cn/official_website/other/serve01-1.png',
        content:'源于实践的行业方案，赋能产业AI升级'
      },
      {  
        title:"营销导流", 
        src: 'https://h5.ophyer.cn/official_website/other/serve02-1.png',
        content:'聚合国内主流媒体，结合AR/VR趣味内容轻松为广告找到优质用户'
      },
      { 
        title:"视听云", 
        src: 'https://h5.ophyer.cn/official_website/other/serve03-1.png',
        content:'简单易用，视听业务更安全合规，让企业安心开展直播、点播业务'
      },
    ],
  },
}
// H5---万界云
const SaasCloudh5 = {
  'advantageList': [
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon1.png',
      title: '产品单独定制化',
      text: 'AR/VR趣味互动内容定制+技术方案定制、部署，全方位满足客户需求'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon2.png',
      title: '一对一运营指导',
      text: '针对品牌方，提供一对一大客户专属运营指导；服务活动全周期'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon3.png',
      title: '资源共享联动营销',
      text: '付费大客户共享资源，联动营销，360°赋能品牌影响力，树立行业标杆'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-ys-icon4.png',
      title: '7*24管家服务',
      text: '100+资深工程师7*24小时在线，全天候保障活动稳定'
    }
  ],
  'industrySolutions': [
    {
      id: 1,
      title: "活动方案策划",
      // tip: '服务活动营销全链路，一体化赋能营销目标',
      lunTitle: '活动内容、策划方案量身定制',
      img: 'https://h5.ophyer.cn/official_website/other/saas-h51.jpg',
      text: '结合客户营销目标与预算，灵活设计活动方案及功能，更符合您的营销诉求'
    },
    {
      id: 2,
      title: "功能模块定制",
      // tip: '服务活动营销全链路，一体化赋能营销目标',
      lunTitle: '产品功能定制，全方位满足客户需求',
      img: 'https://h5.ophyer.cn/official_website/other/saas-h52.jpg',
      text: '支持定制开发、本地部署和API对接，致力于提供全渠道、多场景的营销服务'
    },
    {
      id: 3,
      title: "专属运营",
      // tip: '服务活动营销全链路，一体化赋能营销目标',
      lunTitle: '一对一专属运营指导活动过程全透明可追溯',
      img: 'https://h5.ophyer.cn/official_website/other/saas-h53.jpg',
      text: '签约后即安排一对一的大客户运营经理，全程参与活动运营，依据数据反馈和客户需求，随时调整运营方案'
    },
    {
      id: 4,
      title: "数据复盘",
      // tip: '服务活动营销全链路，一体化赋能营销目标',
      lunTitle: '活动结束后提供数据分析报告营销效果复盘',
      img: 'https://h5.ophyer.cn/official_website/other/saas-h54.jpg',
      text: '对活动结果即时复盘，对比客户预期，总结活动的内容策划、执行方案及奖品设计，支撑后续营销决策'
    }
  ],
  'products': [
    [{
      title: "线下门店场景",
      img: "https://h5.ophyer.cn/official_website/other/saas-icon1.png",
      explain: "引流到店、老带新，提高客单价",
      isNewWindow: true
    },{
      title: "电商场景",
      img: "https://h5.ophyer.cn/official_website/other/saas-icon2.png",
      explain: "店铺引流、关注，引导购买，提升GMV"
    }],[{
      title: "微信生态场景",
      img: "https://h5.ophyer.cn/official_website/other/saas-icon3.png",
      explain: "为用户构建私域流量持续蓄力"
    },{
      title: "App应用场景",
      img: "https://h5.ophyer.cn/official_website/other/saas-icon4.png",
      explain: "API对接实现各类权益体系互通"
    }]
],
  'five': [
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img1.png',
      title: '电商零售'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img2.png',
      title: '3C数码'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img3.png',
      title: '房产家居'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img4.png',
      title: '汽车出行'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img5.png',
      title: '智慧文旅'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img6.png',
      title: '智慧教育'
    },
    {
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img7.png',
      title: '酒店民宿'
    },{
      img: 'https://h5.ophyer.cn/official_website/other/saas-cj-img8.png',
      title: '餐饮服务'
    },
  ]
}
// H5---万效云
const EfficiencyCloudh5 = {
  'advantageList': [
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff01.png"),
      title: 'AR/VR趣味营销内容',
      text: '多种玩法的AR互动游戏以及VR全景沉浸式内容展示，提升用户量扩大品牌影响力、提升营销效果'
    },
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff02.png"),
      title: '精准定位目标人群',
      text: '自动托管，通过强大的计算能力，精准定位目标人群，锁定目标用户，大幅提升ROI'
    },
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff03.png"),
      title: '结算更透明',
      text: '价格透明、数据透明、流程透明；仅收取平台技术服务费用，绝不赚取中间差价，不扣量'
    },
    {
      img: require("../../public/img/icons/EfficiencyCloud/eff04.png"),
      title: '营销阵地',
      text: '对接巨量引擎、广点通、百度信息流等主流媒体投放平台'
    }
  ],
  'second': [
    {
      id: 1,
      tabTitle: 'AR场景广告',
      img: require("@/assets/images/cc.jpg"),
      lunTitle: '趣味游戏+广告，打造个性化AR营销',
      lunTitle1: '打造个性化AR营销',
      tip: '将广告内容与AR互动小游戏完美结合，扩大品牌影响力的同时提升广告效果',
      btns:[
        {
          id:1,
          button:'游戏开始前',
          src1: require('../../public/img/icons/EfficiencyCloud/eff01small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff01Big1.png")
        },
        {
          id:2,
          button:'游戏中',
          src1: require('../../public/img/icons/EfficiencyCloud/eff02small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff02Big1.png")
        }
      ]
    }, 
    {
      id: 2,
      tabTitle: 'VR场景广告',
      img: require("@/assets/images/cc.jpg"),
      lunTitle: '支持多种类型的广告载具完美契合全景内容',
      tip: '透明载具、X展架、电视机、大屏等多种广告载具，与全景内容相融合，颠覆传统广告展现形式',
      btns:[
        {
          id:3, 
          button:'开屏广告', 
          src1: require('../../public/img/icons/EfficiencyCloud/eff04small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff04Big.png")
        },
        {
          id:4, 
          button:'嵌入式', 
          src1: require('../../public/img/icons/EfficiencyCloud/eff05small.png'),
          imgUrl:require("../../public/img/icons/EfficiencyCloud/eff05Big.png")
        }
      ]
    },
  ],
  'four': [
    {
      img: require('@/assets/solutions/944.png'),
      title: '线下门店场景',
      text: '引流到店、老带新，提高客单价'
    },
    {
      img: require('@/assets/solutions/944.png'),
      title: '电商场景',
      text: '店铺引流、关注，引导购买，提升GMV'
    },
    {
      img: require('@/assets/solutions/944.png'),
      title: '微信生态场景',
      text: '拉新、活跃，关注公众号为私域流量构建持续蓄力'
    },
    {
      img: require('@/assets/solutions/944.png'),
      title: 'App应用场景',
      text: 'API对接实现各类权益体系互通助力用户长线运营'
    }
  ]
}
// 开发者---教程
const developCase = [
  {
    title: '全部',
    tabItem: [
      {
        id:'xinhsoujiaoxu',
        itemTitle: '新手教学',
        itemTip: '编辑器入门',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg1.png',
      },
      {
        id:'chuangzuokaishi',
        itemTitle: '创作开始：制作你的第一个领地',
        itemTip: '学习如何搭建领地',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg2.png',
      },
      {
        id:'sucaijianjie',
        itemTitle: '素材商店简介',
        itemTip: '如何上传或购买素材',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg3.png',
      },
      {
        id:'chuangjianVR',
        itemTitle: '如何创建VR全景',
        itemTip: '模板创建或者DIY都可以',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg4.png',
      },
      {
        id:'VRgongneng',
        itemTitle: 'VR编辑器功能介绍',
        itemTip: '一篇文章搞懂VR编辑器',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg5.png',
      },
      {
        id:'VRfuzhu',
        itemTitle: 'VR编辑器辅助功能介绍',
        itemTip: '辅助功能更好的帮助你管理和创建VR',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg6.png',
      },
      {
        id:'3Djiemian',
        itemTitle: '3D编辑器界面说明',
        itemTip: '简单了解3D编辑器',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg7.png',
      },      
      {
        id:'3Dgongeng',
        itemTitle: '3D编辑器功能介绍',
        itemTip: '学习如何使用3D编辑器',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg8.png',
      },        
      {
        id:'shangpinredian',
        itemTitle: '如何在全景中添加商品热点',
        itemTip: '强大的热点功能实操',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg9.png',
      },
      {
        id:'shapangongneng',
        itemTitle: '如何使用沙盘功能',
        itemTip: '为VR场景添加导航功能',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg10.png',
      },
      {
        id:'3Djiaoben',
        itemTitle: '如何使用3D编辑器编写脚本',
        itemTip: '让3D元素动起来',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg11.png',
      },
    ]
  },
  {
    title: '编辑器入门',
    tabItem: [
      {
        id:'xinhsoujiaoxu',
        itemTitle: '新手教学',
        itemTip: '编辑器入门',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg1.png',
      },
      {
        id:'chuangzuokaishi',
        itemTitle: '创作开始：制作你的第一个领地',
        itemTip: '学习如何搭建领地',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg2.png',
      },
      {
        id:'sucaijianjie',
        itemTitle: '素材商店简介',
        itemTip: '如何上传或购买素材',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg3.png',
      },
    ]
  },
  {
    title: 'VR编辑器',
    tabItem: [
      {
        id:'chuangjianVR',
        itemTitle: '如何创建VR全景',
        itemTip: '模板创建或者DIY都可以',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg4.png',
      },
      {
        id:'VRgongneng',
        itemTitle: 'VR编辑器功能介绍',
        itemTip: '一篇文章搞懂VR编辑器',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg5.png',
      },
      {
        id:'VRfuzhu',
        itemTitle: 'VR编辑器辅助功能介绍',
        itemTip: '辅助功能更好的帮助你管理和创建VR',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg6.png',
      },
    ]
  },
  {
    title: '3D编辑器',
    tabItem: [
      {
        id:'3Djiemian',
        itemTitle: '3D编辑器界面说明',
        itemTip: '简单了解3D编辑器',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg7.png',
      },      
      {
        id:'3Dgongeng',
        itemTitle: '3D编辑器功能介绍',
        itemTip: '学习如何使用3D编辑器',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg8.png',
      },        
    ]
  },
  {
    title: '技巧',
    tabItem: [
      {
        id:'shangpinredian',
        itemTitle: '如何在全景中添加商品热点',
        itemTip: '强大的热点功能实操',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg9.png',
      },
      {
        id:'shapangongneng',
        itemTitle: '如何使用沙盘功能',
        itemTip: '为VR场景添加导航功能',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg10.png',
      },
      {
        id:'3Djiaoben',
        itemTitle: '如何使用3D编辑器编写脚本',
        itemTip: '让3D元素动起来',
        coverImg: 'https://h5.ophyer.cn/official_website/other/develop-caseImg11.png',
      },
    ]
  },
]

const DevelopHome = {
  'list':[
    {id:1,title:"VR展厅",h2:'VR展厅',img:'https://h5.ophyer.cn/official_website/other/develop2-1.png',sub:'沉浸式交互体验，全面展示品牌信息',content:'• 720°可交互全景，为您量身打造线上虚拟展厅 <br/>• 全景编辑制作平台，简单操作即可生成场景<br/> • 热点、嵌入、虚拟人物等特色功能，适配更多营销场景'},
    {id:2,title:"VR带看",h2:'VR带看',img:'https://h5.ophyer.cn/official_website/other/developVRLook.png',sub:'互动演示，实现VR远程带看',content:'• 邀请多个用户共同加入联动，远程演示更方便<br/>• 打破空间和距离，无障碍交流更高效<br/>• 语音、说一说实时互动，交流本该如此简单'},
    {id:3,title:"3D模型",h2:'3D模型',img:'https://h5.ophyer.cn/official_website/other/develop3D.png',sub:'3D场景模型，产品体验更真实',content:'• 支持FBX、GLTF多种模型导入，适用性更强<br/>• 3D展示产品信息，交互更强，距离更近<br/>• 增强交互体验，助力商家提升营销效果'},
    {id:4,title:"360环物",h2:'360环物',img:'https://h5.ophyer.cn/official_website/other/develop360.png',sub:'贴合电商场景，让产品“动”起来',content:'• 简单拍摄即可生成360环物<br/>• 打破传统的静态图片展示，更多细节展示<br/>• 电商、汽车、展馆等多场景适用'},
    {id:5,title:"虚拟导购",h2:'虚拟导购',img:'https://h5.ophyer.cn/official_website/other/developdaogou.png',sub:'虚拟形象导购+智能语音讲解，让场景更具亲和力',content:'• 系统提供多款形象多款动作，适用性更强<br/>• 支持添加欢迎语，强化第一印象<br/>• 动作引导结合语音讲解，代入感更强'},
  ],
  'product':[
    {icon:require('../../public/img/icons/develop/develop4-1@2.png'),width:'126px',title:'图形化界面',content:'提供图形化的编辑页面，让3D场景搭建，炫酷动画的制作变得简单'},
    {icon:require('../../public/img/icons/develop/develop4-2@2.png'),width:'128px',title:'低代码脚本',content:'详尽的生命周期函数，简单易用的预制接口，让用户通过少量代码即可写出优雅的脚本'},
    {icon:require('../../public/img/icons/develop/develop4-3@2.png'),width:'131px',title:'跨平台 多终端',content:'支持安卓，IOS，H5小程序等多渠道同时发布，确保触达更多用户场景'}
  ],
  'VRList':[
    {img:'https://h5.ophyer.cn/official_website/other/develop3-1.png',width:'30px',icon:require('../../public/img/icons/develop/icon3-1@2.png'),h2:'电商零售',sub:'轻松操作打造全景云店 助力营销转化',p:'超沉浸体验、虚拟导购及线上线下引流，为营销活动持续蓄力'},
    {img:'https://h5.ophyer.cn/official_website/other/developPC3-2.png',width:'30px',icon:require('../../public/img/icons/develop/icon3-2@2.png'),h2:'房产家居',sub:'3D实景复刻，还原真实空间，房屋一览无遗',p:'720°全景看房+沙盘+智能解说+同屏连线，随时随地高效看房'},
    {img:'https://h5.ophyer.cn/official_website/other/developPC3-3.png',width:'30px',icon:require('../../public/img/icons/develop/icon3-3@2.png'),h2:'智慧文旅',sub:'数字科技+文化创意让景区焕发全新生命力',p:'通过AR/VR技术手段，发展沉浸式互动文旅消费内容，推动景区数字化转型升级'},
    {img:'https://h5.ophyer.cn/official_website/other/developPC3-4.png',width:'32px',icon:require('../../public/img/icons/develop/icon3-4@2.png'),h2:'智慧教育',sub:'VR沉浸式+强交互情景教学，参与感更强，提升教学质量 ',p:'涵盖基础教育、高等教育、社会培训等多领域的智慧教育平台'},
    {img:'https://h5.ophyer.cn/official_website/other/developPC3-5-1.png',width:'34px',icon:require('../../public/img/icons/develop/icon3-5@2.png'),h2:'汽车行业',sub:'汽车高模结合VR技术带来线上看车新体验',p:'高精度建模，702°可交互查看汽车外观及内饰支持添加留咨表单，为门店强势引流'}
  ],
  'Dlist':[
    {img:'https://h5.ophyer.cn/official_website/other/develop5-1new.png',width:'27px',icon:require('../../public/img/icons/develop/icon3D5-1@2.png'),h2:'3D场景搭建',sub:'快速搭建3D场景',p:'平台预设的3D模型，搭配用户上传的贴图可以快速搭建业务场景所需要的3D场景'},
    {img:'https://h5.ophyer.cn/official_website/other/develop5-2.png',width:'28px',icon:require('../../public/img/icons/develop/icon3D5-2@2.png'),h2:'动画制作',sub:'序列帧、粒子动画快速生成',p:'序列帧动画编辑工具可以快速制作序列帧动画，内置粒子工具轻松制作粒子动画特效，让动画效果更上一层楼'},
    {img:'https://h5.ophyer.cn/official_website/other/develop5-3.png',width:'28px',icon:require('../../public/img/icons/develop/icon3D5-3@2.png'),h2:'脚本自定义',sub:'预设API，场景表现更随心',p:'通过预设API控制场景元素运动及动画的播放，让场景的表现更随心'},
  ],
  'imgArr': [
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-01.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-02.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-03.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-04.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-05.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-06.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-07.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-08.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-09.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-10.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-11.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-12.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-13.png',
    'https://h5.ophyer.cn/official_website/other/vrPanorams-xuliezheng-14.png',
  ],
}



 
export default {
  EfficiencyCloud,
  DigitalMuseum,
  VRSceneShow,
  solutionAR,
  audioSolution,
  roleSolution,
  bigDataAnalysis,
  industrySolution,
  productService,
  application,
  SaasCloudh5,
  EfficiencyCloudh5,
  developCase,
  DevelopHome
}